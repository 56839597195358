import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from "gatsby"
import RSSSDTimesFeedHeader from '../../StaticQueries/RSSSDTimesFeedHeader'
import RSSFeedGraphic01 from '../../StaticQueries/RSSFeedGraphic01'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  container: {
    padding: '48px 36px 0',
  },
})


function FeedContent() {
  const data = useStaticQuery(graphql`
    {
      allFeedSdTimes {
        edges {
          node {
            title
            link
          }
        }
      }
    }
  `)

  const feedDataMap = new Map();
  let feedItems = data.allFeedSdTimes.edges

  feedItems.forEach(entry => {
    for (const title in entry.node.title) {
      feedDataMap.set(entry.node.title, entry.node.link)
    }
  })

  const titles = [...feedDataMap.keys()];
  const urls = [...feedDataMap.values()];

  return titles.map(title => (
    <li>
      <a href={feedDataMap.get(title)} style={{ color: 'rgb(255,255,0)' }}><font color="#E67E22">{title}</font></a>
    </li>
  ))
}


function RSSSDTimesContent({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= R°S°S ≡≡≡ S°D ≡≡≡ T°i°m°e°s ≡≡≡ F°e°e°d =-</h1> */}
            <RSSSDTimesFeedHeader />
          </AppBar>

          <div className={classes.contentWrapper} style={{color: 'blue'}}>
            {/* <Typography color="textSecondary" align="justify"> */}
						<typography color="textPrimary" align="justify">
              <RSSFeedGraphic01 />
              <hr/>
              <h2 style={{color: 'green'}}>LATEST ARTICLES:</h2>
              <br/>
              (As Of Latest Application Build Time)
              <hr />
              <ol id="rssSDTimesListRef">
                <FeedContent />
              </ol>
            </typography>
            {/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

RSSSDTimesContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RSSSDTimesContent)
